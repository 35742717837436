import { SingleAcceptance } from "smartbox-types"
import { ButtonsContainer, Section, SimpleHeader } from "../../../components/Layout"
import { __ } from "../../../helpers/i18n";
import { Button } from "../../../components/Branded";
import Input from "../Input";
import { ChangeEvent } from "react";
import CheckBox from "../CheckBox";
import styled from "styled-components";
import { v4 } from "uuid";

interface Props {
    value: SingleAcceptance[];
    onChange: (acceptances: SingleAcceptance[]) => void;
}

const StyledAcceptances = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.primary}`,
    marginTop: 12,
    paddingBottom: 16,
    marginBottom: 16
}))

const StyledSingleAcceptance = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.colors.primary}`,
    borderRadius: 6,
    padding: 8,
    marginBottom: 16
}))

export const AcceptancesField = ({ value, onChange }: Props) => {
    const acceptances = value || [];

    const addAcceptance = () => {
        onChange([...acceptances, { text: 'Treść zgody', required: false, id: v4() }])
    }

    const updateAcceptanceText = (indexToChange: number, e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        const newAcceptances = acceptances.map((acceptance, index) => {
            if (index === indexToChange) {
                return {
                    ...acceptance,
                    text: value
                }
            }
            return acceptance;
        })

        onChange(newAcceptances);
    }

    const updateAcceptanceRequired = (indexToChange: number, checked: boolean) => {

        const newAcceptances = acceptances.map((acceptance, index) => {
            if (index === indexToChange) {
                return {
                    ...acceptance,
                    required: checked
                }
            }
            return acceptance;
        })

        onChange(newAcceptances);
    }

    const deleteAcceptance = (indexToDelete: number) => {
        const newAcceptances = acceptances.filter((_, index) => index !== indexToDelete);
        onChange(newAcceptances);
    }


    return <div>
        <label className='input-label compact'>{__('application.addonAcceptances')}</ label>
        <StyledAcceptances>
            {acceptances.map((acceptance, index) => <StyledSingleAcceptance key={index}>
                <p>{__('application.acceptanceId')} - <strong>{acceptance.id}</strong></p>
                <Input type='text' input={{
                    label: 'application.acceptanceText',
                    name: `acceptance_text_${index}`,
                    value: acceptance.text,
                    onChange: (value: ChangeEvent<HTMLInputElement>) => updateAcceptanceText(index, value)
                }} />
                <CheckBox label='application.acceptanceRequired'
                    name={`acceptance_required_${index}`}
                    checked={acceptance.required}
                    handleChange={(checked: boolean) => updateAcceptanceRequired(index, checked)}
                />
                <ButtonsContainer>
                    <Button danger text="application.delete" click={() => deleteAcceptance(index)} />
                </ButtonsContainer>
            </StyledSingleAcceptance>)}
            <ButtonsContainer>
                <Button text="application.addAddonAcceptance" click={addAcceptance} />
            </ButtonsContainer>
        </StyledAcceptances>
    </div>
}